import {  useNavigate } from "react-router-dom";
import Button from "../components/Button";
import PhoneInput from "../components/PhoneInput";
import copys from '../utils/copys'
import { useEffect, useState } from "react";
import { sendOtp } from "../services/authService";
import { Logo } from "../components/Logo";
import { useAuthContext } from "../hooks/useAuthContext";
import { TermsPrivacy } from "../components/TermsPrivacy";
import { setOtpRetries } from "../utils/otpRetries";
import LoadingSpinner from "../components/LoadingSpinner";
import { routePaths, toastTypes } from "../utils/constants";
import gtmService from '../services/gtmService';
import { useConfigContext } from "../hooks/useConfigContext";
import * as Sentry from '@sentry/react'
import toast from "react-hot-toast";
import Toast from "../components/Toast";

export default function ScreenSignUp() {
    
    const [phone, setPhone] = useState('');
    const [buttonEnabled, setButtonEnabled] = useState(false);
    const navigate = useNavigate();
    const {userData,setUserData}= useAuthContext();
    const [isLoading,setIsLoading]=useState(false);
    const {countryCodes} = useConfigContext();


    const getPhoneValue = value => {
        setPhone(value);
    }

    useEffect(()=>{
      if(userData.isLogged){
          navigate('/');
      }
      gtmService.pushPageView('/cliente-registro-numero','Cliente: Registro numero')
    },[])

    useEffect(()=>{
        if(phone.length<14){
            setButtonEnabled(false);
        }else{
            setButtonEnabled(true);
        }
    },[phone])

    const handleClick = async()=>{
        if(phone.length<14){
            return;
        }
        gtmService.pushEvent({userID:`UID${phone.replace('+52 ','')}`});
        gtmService.pushClickButton(copys.buttonCreateAccount)
        setIsLoading(true);
        const {successful,message,errors} = await sendOtp(phone);
        setIsLoading(false);
        if(successful){
            const newOtpRetries = setOtpRetries(phone,userData.otpRetries);
            setUserData({...userData,phoneToValidate:phone,otpRetries:newOtpRetries,loginOrRegister:'registro'});
            navigate('/'+routePaths.OTP)
        }else{
            Sentry.captureException(errors, {
                tags: {
                    flow: 'send_otp',
                    phone_number: phone,
                },
            });
            toast.custom(t => (<Toast message={message} type={toastTypes.ERROR} toastId={t.id} />))
            console.log('error in sending otp',errors);
        }
    }


  return (
    <div className="h-screen pt-10 bg-money-image bg-cover bg-center flex flex-col items-center">
        <div className="container p-3 text-center min-[480px]:w-[480px]">
            <div className="my-3 mb-5">
                <Logo/>
            </div>
            <div className="my-3">
                <div className="text-white font-libre-franklin text-3xl font-bold">{copys.welcomeMessage}</div>
            </div>
            <div className="my-3">
                <PhoneInput required onChange={getPhoneValue} countryCodes={countryCodes} />
            </div>
            <div className="my-3 px-5 flex justify-center">
                <Button label={copys.buttonCreateAccount} enabled={buttonEnabled} onClick={handleClick} isLoading={isLoading} icon={isLoading && { icon:<LoadingSpinner width={20} color={'#0C0D0F'} />,position:'end'}}/>
            </div>
            <TermsPrivacy />
        </div>
    </div>
  )
}
