import { miscClient } from '../config/axios';
import { axiosRequest } from '../utils/axiosRequest';
import { httpVerbs } from '../utils/constants';

export const getConfiguration = async () => {
    return await axiosRequest(miscClient, httpVerbs.GET, '/v1/general_config/consumer');
}

export const getPrivacyPolicies = async () => {
    return await axiosRequest(miscClient ,httpVerbs.GET, '/v1/content/site_privacy')
}

export const getTermsAndConditions = async () => {
    return await axiosRequest(miscClient, httpVerbs.GET, '/v1/content/terms')
}
