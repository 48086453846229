import React, { useEffect, useRef, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import ChipButton from '../ChipButton';
import copys from '../../utils/copys';
import { promoStatus, routePaths } from '../../utils/constants';
import { useAuthContext } from '../../hooks/useAuthContext';
import { usePromoContext } from '../../hooks/usePromoContext';
import './Carousel.css';
import CountdownTimer from '../CountdownTimer';
import {ReactComponent as ClockSvg} from '../../assets/ilustraciones/clock.svg';
import gtmService from '../../services/gtmService';



export default function Card({promo,isImageOnly,status}) {

  const [chipButtonLabelAndClass,setChipButtonLabelAndClass] = useState({label:copys.buttonBannerAvailable,class:'bg-success'});
  const navigate = useNavigate();
  const {userData}=useAuthContext();
  const {setPromoData} = usePromoContext();
  const parentChipButtonRef = useRef(null);
  const rightChipPositionValues = {
    [promoStatus.ACTIVE]:'3',
    [promoStatus.ONHOLD]:'6',
    [promoStatus.DISABLED]:'3',
  }


  const goToPromo = ()=>{
    if(isImageOnly) return;

    gtmService.pushViewOrSelectPromotion(false,promo.id,userData.isLogged,promo.description)
    
    setPromoData({promoId:promo.id})
    if(userData.isLogged){
      navigate(`/${routePaths.DETAIL.replace(':promoId',promo.id)}`);
    }else{
      navigate('/'+routePaths.LOGIN);
    }
  }

  const changeChipToAvailable = ()=>{
    setChipButtonLabelAndClass({label:copys.buttonBannerAvailable,class:'bg-success'})
    parentChipButtonRef.current.classList.remove('right-6');
    parentChipButtonRef.current.classList.add('right-3');
  }

  useEffect(()=>{
    if(!isImageOnly){
      gtmService.pushViewOrSelectPromotion(true,promo.id,userData.isLogged,promo.description)
    }

    if(userData.isLogged){
      if(status===promoStatus.ONHOLD){
        const chipButtonColorClass='bg-neutral_weak';
        const chipButtonlabel = <div className='flex items-center gap-1'><ClockSvg/> <CountdownTimer targetDate={promo.last_exchange_time} onComplete={()=>changeChipToAvailable()}/></div> ;
        setChipButtonLabelAndClass({label:chipButtonlabel,class:chipButtonColorClass})
      }else if(status===promoStatus.DISABLED){
        const chipButtonColorClass='bg-neutral_weak';
        const chipButtonlabel=copys.buttonBannerDisabled;
        setChipButtonLabelAndClass({label:chipButtonlabel,class:chipButtonColorClass})
      } 
    }
  },[])
  

  return (
    <div id={`card_${promo.id}`} className={`container sm:w-72 flex flex-col justify-center items-center relative`} onClick={goToPromo} >
      { promo?.banner_url && <img src={promo.banner_url} alt="banner" className={`h-48 min-w-full ` + (status === promoStatus.DISABLED ? ' grayscale':'')} /> }
      {
        !isImageOnly && (
          <>
            <div className=' right-6 invisible right-3'>{/* for tailwindCss generated output only */}</div>
            <div ref={parentChipButtonRef} className={`absolute right-${rightChipPositionValues[status]||'3'} bottom-2`} >
              <ChipButton id={promo.id} label={chipButtonLabelAndClass.label} colorClass={chipButtonLabelAndClass.class}/>
            </div>
          </>
        )
      }
    </div>
  )
}
