import { couponsClient } from '../config/axios';
import { axiosRequest } from '../utils/axiosRequest';
import { httpVerbs } from '../utils/constants';

export const getPromos = async () => {
    return await axiosRequest(couponsClient, httpVerbs.GET, '/v1/coupon');
}

export const getPromoById = async (promoId) => {
    return await axiosRequest(couponsClient, httpVerbs.GET, '/v1/coupon/' + promoId);
}

export const activatePromo = async (promotion_id) => {
    return await axiosRequest(couponsClient, httpVerbs.POST, '/v1/coupon/activate', { promotion_id });
}
