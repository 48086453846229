import axios from "axios";

export const authClient = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL
})

export const couponsClient = axios.create({
    baseURL: process.env.REACT_APP_COUPONS_URL
})

export const miscClient = axios.create({
    baseURL: process.env.REACT_APP_MISC_URL
})

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})
